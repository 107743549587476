<template>
  <div class="box">
    <van-sticky :z-index="9999" :offset-top="0.1">
      <NavBar>
        <div class="title" slot="title" style="color: #fff">
          {{ $t("Mt4ApiMenu.quote") }}
        </div>
      </NavBar>
    </van-sticky>
    <div class="complex">
      <van-cell-group
        v-for="(item, index) in eightList"
        :key="index"
        class="show-item"
        @click="showGroup(item)"
      >
        <van-swipe-cell>
          <van-cell
            :title="item.symbol"
            :label="$t('Mt4ApiTrade.spread') + ': ' + showSpeed(item)"
          />
          <van-cell
            :class="[item.bidup ? 'changeRed' : 'changeGreen']"
            :label="$t('Mt4ApiTrade.low') + ': ' + item.low"
          >
            <template slot="title">
              <!-- if xiaoshu >= 3 -->
              <span
                class="custom-title"
                v-if="
                  forPrices(item.bid, item.digits).replace(/\d+\.(\d*)/, '$1')
                    .length >= 3
                "
              >
                <span>{{ forPrices(item.bid, item.digits) | numFilter }}</span>
                <span class="changeBig">{{
                  forPrices(item.bid, item.digits) | bigNum
                }}</span>
                <span class="power">{{
                  forPrices(item.bid, item.digits) | lastNum
                }}</span>
              </span>
              <!-- if xiaoshu <= 1 -->
              <span
                class="custom-title"
                v-else-if="
                  forPrices(item.bid, item.digits).replace(/\d+\.(\d*)/, '$1')
                    .length <= 1
                "
              >
                <span>{{
                  forPrices(item.bid, item.digits) | inBeforeNum
                }}</span>
                <span class="changeBig"
                  >{{ forPrices(item.bid, item.digits) | beforeNum }}.</span
                >
                <span class="power">{{
                  forPrices(item.bid, item.digits) | lastBefore
                }}</span>
              </span>
              <!-- if xiaoshu = 2 -->
              <span class="custom-title" v-else>
                <span>{{
                  forPrices(item.bid, item.digits) | numFilterTwo
                }}</span>
                <span class="changeBig">{{
                  forPrices(item.bid, item.digits) | lastTwo
                }}</span>
              </span>
            </template>
          </van-cell>
          <van-cell
            :class="[item.askup ? 'changeRed' : 'changeGreen']"
            :label="$t('Mt4ApiTrade.high') + ': ' + item.high"
          >
            <template slot="title">
              <!-- if xiaoshu >= 3 -->
              <span
                class="custom-title"
                v-if="
                  forPrices(item.ask, item.digits).replace(/\d+\.(\d*)/, '$1')
                    .length >= 3
                "
              >
                <span>{{ forPrices(item.ask, item.digits) | numFilter }}</span>
                <span class="changeBig">{{
                  forPrices(item.ask, item.digits) | bigNum
                }}</span>
                <span class="power">{{
                  forPrices(item.ask, item.digits) | lastNum
                }}</span>
              </span>
              <!-- if xiaoshu <= 1 -->
              <span
                class="custom-title"
                v-else-if="
                  forPrices(item.ask, item.digits).replace(/\d+\.(\d*)/, '$1')
                    .length <= 1
                "
              >
                <span>{{
                  forPrices(item.ask, item.digits) | inBeforeNum
                }}</span>
                <span class="changeBig"
                  >{{ forPrices(item.ask, item.digits) | beforeNum }}.</span
                >
                <span class="power">{{
                  forPrices(item.ask, item.digits) | lastBefore
                }}</span>
              </span>
              <!-- if xiaoshu = 2 -->
              <span class="custom-title" v-else>
                <span>{{
                  forPrices(item.ask, item.digits) | numFilterTwo
                }}</span>
                <span class="changeBig">{{
                  forPrices(item.ask, item.digits) | lastTwo
                }}</span>
              </span>
            </template>
          </van-cell>
          <template #right>
            <van-button
              square
              type="primary"
              :text="$t('Mt4ApiMenu.chart')"
              color="#13b5b1"
              @click="toChart(item)"
            />
            <van-button
              square
              type="danger"
              :text="$t('Mt4ApiTrade.delete')"
              @click="removeSymbol(item, index)"
            />
          </template>
        </van-swipe-cell>
      </van-cell-group>
      <van-action-sheet
        v-model="show"
        :actions="actions"
        :cancel-text="$t('cancel')"
        close-on-click-action
        @cancel="onCancel"
        @select="onSelect"
        @closed="onClosed"
        :description="symbolName.symbol"
      />
    </div>
    <div class="addCommon">
      <van-row>
        <van-col span="8"></van-col>
        <van-col span="8"
          ><van-button
            type="primary"
            color="#13b5b1"
            icon="plus"
            size=".1rem"
            @click="addCommond"
            style="border-radius: 6px; width: 100%; height: 0.7rem"
            >{{ $t("Mt4ApiTrade.addSymbol") }}</van-button
          ></van-col
        >
        <van-col span="8"></van-col>
      </van-row>
    </div>
  </div>
</template>
<script>
import { GetSymbols } from "@/api/mt4Api";
import NavBar from "@/components/mt4/NavBar";
import { forPrice } from "@/utils/commons";
import baseConfig from "@/utils/baseConfig";
export default {
  components: {
    NavBar,
  },
  data() {
    return {
      noSet: [],
      count: 0,
      logo: require("@/assets/image/logo.png"),
      active: 2,
      show: false,
      actions: [
        {
          name: this.$t("Mt4ApiMenu.trade"),
          color: "",
          className: "actionSheet",
        },
        { name: this.$t("Mt4ApiMenu.chart"), className: "actionSheet" },
        /*{ name: "详细情况", className: "actionSheet" },*/
        { name: this.$t("Mt4ApiTrade.delete"), className: "actionSheet" },
      ],
      checked: true,
      border: true,
      eightList: [],
      actionsIndex: 0,
      isShow: true,
      askup: false,
      bidup: false,
      symbolName: "XAUUSD",
      path: baseConfig.socketPath,
      socket: "",
      socketContent: "",
      userinfo: this.$store.state.mt4userinfo,
      tianjia: [],
      giveLength: null,
    };
  },
  filters: {
    numFilter(value) {
      return value.substr(0, value.length - 3);
    },
    bigNum(value) {
      return value.substr(value.length - 3, 2);
    },
    lastNum(value) {
      return value.substr(value.length - 1, 1);
    },
    numFilterTwo(value) {
      return value.substr(0, value.length - 2);
    },
    lastTwo(value) {
      return value.substr(value.length - 2, 2);
    },
    inBeforeNum(value) {
      return parseInt(value.substring(0, value.indexOf(".")))
        .toString()
        .substr(
          0,
          parseInt(value.substring(0, value.indexOf("."))).toString().length - 2
        );
    },
    beforeNum(value) {
      return parseInt(value.substring(0, value.indexOf(".")))
        .toString()
        .substr(
          parseInt(value.substring(0, value.indexOf("."))).toString().length -
            2,
          2
        );
    },
    lastBefore(value) {
      return value.replace(/\d+\.(\d*)/, "$1");
    },
  },
  created() {
    this.initialization();
    this.init();
  },
  mounted() {
    this.$store.commit("toggleTabbarShow", true);
    this.$store.commit("toggleTabbarCurrentShow", "Mt4");
    var h = document.documentElement.clientHeight || document.body.clientHeight;
    // document.getElementsByClassName('box')[0].style.height = (h) + "px";
    // document.getElementsByClassName('van-pull-refresh')[0].style.height = (h) + "px";
  },
  methods: {
    toChart(item) {
      this.$router.push({
        path: "/chart",
        query: {
          symbol: item.symbol,
          digits: item.digits,
        },
      });
    },
    showSpeed(item) {
      return parseInt((item.ask - item.bid) * Math.pow(10, item.digits));
    },
    forPrices(value, num) {
      return forPrice(value, num);
    },
    removeSymbol(item, index) {
      this.deleteAndAdd(item, index);
    },
    addCommond() {
      this.$router.push("/quotes/classtab");
    },
    showGroup(item) {
      this.show = true;
      this.symbolName = item;
    },
    showRed(active) {
      active == 1 ? (this.isShow = false) : this.isShow == true;
    },
    onCancel() {},
    onClosed() {},
    onSelect(item, index) {
      // 默认情况下点击选项时不会自动收起
      // 可以通过 close-on-click-action 属性开启自动收起
      this.show = false;
      this.describe = this.symbolName.symbol;
      switch (index) {
        case 0:
          this.$router.push({
            path: "/neworder",
            query: {
              symbol: this.symbolName.symbol,
              digits: this.symbolName.digits,
              orderType: 0,
              cmd: 1,
            },
          });
          break;
        case 1:
          this.$router.push({
            path: "/chart",
            query: {
              symbol: this.symbolName.symbol,
              digits: this.symbolName.digits,
            },
          });
          break;
        case 2:
          this.eightList.forEach((item) => {
            if (this.symbolName.symbol == item.symbol) {
              let index = this.eightList.indexOf(item);
              this.deleteAndAdd(item, index);
            }
          });
          break;
      }
    },
    init: function () {
      if (typeof WebSocket === "undefined") {
        alert("您的浏览器不支持socket");
      } else {
        let _this = this;
        // 实例化socket
        this.socket = new WebSocket(this.path);
        this.socket.binaryType = "blob";
        // 监听socket连接
        this.socket.onopen = this.open;
        // 监听socket错误信息
        this.socket.onerror = this.error;
        // 监听socket消息
        this.socket.onmessage = this.getMessage;
        // 路由跳转时结束 websocket 链接
        this.$router.afterEach(function () {
          _this.socket.close();
        });
      }
    },
    open: function () {
      // console.log("socket连接成功");
      this.send();
    },
    error: function () {
      console.log("连接错误");
    },
    getMessage: function (msg) {
      if (msg.data instanceof Blob) {
        const reader = new FileReader();
        reader.readAsText(msg.data);
        reader.onload = () => {
          this.socketContent = reader.result;
          const json = JSON.parse(this.socketContent);
          if (parseInt(json.status) == 200) {
            if (json.list.length) {
              const price = json.list;
              // console.log(price);
              const oldList = this.eightList;
              for (let i = 0; i < price.length; i++) {
                let name = price[i].symbol;
                for (let j = 0; j < oldList.length; j++) {
                  if (oldList[j].symbol == name) {
                    //更新旧数据
                    this.askup = price[i].ask > oldList[j].ask ? true : false;
                    this.bidup = price[i].bid > oldList[j].bid ? true : false;
                    price[i].askup = this.askup;
                    price[i].bidup = this.bidup;
                    price[i].group_name = oldList[j].group_name;
                    this.$set(oldList, j, price[i]);
                    break;
                  }
                }
              }
            }
          } else {
            console.log("no price");
          }
        };
      } else {
        console.log("Result: " + msg.data);
      }
    },
    send: function () {
      const data = {
        clientid: this.userinfo.clientid,
      };
      const handshake = JSON.stringify(data);
      this.socket.readyState == WebSocket.OPEN
        ? this.socket.send(handshake)
        : undefined;
      setTimeout(this.send, 1000);
    },
    close: function () {
      console.log("socket已经关闭");
    },
    onInput(checked) {
      // console.log(checked);
      if (checked) {
        this.quoteTitle = "�߼�";
      } else {
        this.quoteTitle = "��";
      }
      this.checked = checked;
    },
    deleteAndAdd(item, index) {
      this.eightList.splice(index, 1);
      let addarr = JSON.parse(localStorage.getItem("addArr"));
      addarr.splice(
        addarr.findIndex((itemer) => itemer.symbol === item.symbol),
        1
      );
      localStorage.setItem("addArr", JSON.stringify(addarr));
      if (index > this.giveLength - 1) {
        let odd = JSON.parse(localStorage.getItem("odd"));
        odd.push(item);
        localStorage.setItem("odd", JSON.stringify(odd));
      }
    },
    async initialization() {
      let res = await GetSymbols();
      this.giveLength = res.msg.CommonSymbol.length;
      localStorage.getItem("odd") == null
        ? localStorage.setItem("odd", JSON.stringify(res.msg.AllSymbol))
        : "";
      if (localStorage.getItem("addArr") != null) {
        const addArr = localStorage.getItem("addArr");
        this.tianjia.push(...JSON.parse(addArr));
        this.noSet.push(...res.msg.CommonSymbol, ...this.tianjia);
        this.eightList = [...new Set(this.noSet)];
      } else {
        this.eightList.push(...res.msg.CommonSymbol);
      }
      localStorage.setItem("exits", JSON.stringify(this.tianjia));
    },
  },
  destroyed() {
    // 销毁监听
    this.socket.onclose = this.close;
  },
};
</script>

<style>
.produce-header {
  padding: 0 10px;
}
.quotes .van-cell__value {
  display: flex;
}
.quotes .van-cell__label {
  margin-top: 0;
}
.output {
  padding: 5px 15px;
  background-color: #efeff1;
}
.quotes {
  overflow-y: auto;
  /*background-color: #f7f7f7;*/
}

.header {
  text-align: center;
  border-bottom: 1px solid #eee;
}

.header > div {
  padding: 10px;
}

.show-nav {
  color: #333;
  font-size: 15px;
  font-weight: 700;
  display: flex;
  position: relative;
}

.showAction {
  background: rgba(0, 0, 0, 0.2);
}

.switch {
  width: 80%;
  padding: 8px 10px;
  display: flex;
  justify-content: content;
}

.van-overlay {
  background-color: rgba(0, 0, 0, 0.2) !important;
}

.switch-active {
  color: red;
}

.high,
.low {
  position: absolute;
}

.quotes-left,
.quotes-right {
  font-size: 20px;
}

.quotes .van-cell-group__title {
  padding: 0 8px;
  color: #969799;
  font-size: 14px;
  line-height: 16px;
}

.quotes .van-cell.changeRed {
  color: red;
  font-size: 15px;
}

.quotes .van-cell.changeGreen {
  color: #13b5b1;
  font-size: 15px;
}

.quotes .van-nav-bar .van-icon.modify {
  color: #d0d0d0;
}

.quotes .van-nav-bar__title {
  width: 55%;
}

.quotes .van-tabs__nav.van-tabs__nav--card {
  padding: 0.075rem;
  border-radius: 0.1rem;
  height: 0.75rem;
}

.quotes .van-tabs--card > .van-tabs__wrap {
  height: 0.75rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.quotes .van-tab {
  border-radius: 0.06rem;
}

.timer {
  position: absolute;
  top: 0.08rem;
  left: 0.45rem;
  z-index: 9999;
  color: #969799;
  font-weight: 500;
  font-size: 0.35rem;
}

.quotes .van-cell__title {
  position: relative;
  /*top: .25rem;*/
}
.addCommon {
  padding: 0.3rem 0 79px 0;
  background-color: #fff;
}
.addCommon .van-button__icon {
  font-size: 12px;
}
.actionSheet {
  line-height: 7px;
  font-size: 15px;
}
.show-item .van-swipe-cell__wrapper {
  display: flex;
}
.show-item .van-cell__title > span {
  font-weight: 700;
}
.show-item .van-button {
  height: 100% !important;
}
.box .van-card__content {
  min-height: 0;
}
.box .van-image {
  width: 50px !important;
  height: 43px !important;
  padding: 5px;
  background-color: #fff;
}
.box .van-card {
  padding: 1px;
}
.box .van-card__thumb {
  margin-right: 0;
}
.box .van-nav-bar {
  background-color: #13b5b1;
}
.changeBig {
  font-size: 20px;
}
.custom-title {
  position: relative;
}
.van-cell.changeGreen,
.van-cell.changeRed .van-cell__title {
  position: relative;
}
.power {
  position: absolute;
  top: -9px;
}
/* .box .van-icon {
  position: absolute;
  top: 6px;
  left: 68px;
} */
.box .van-icon {
  margin-left: 5px;
}
.box .van-button {
  font-size: 12px;
}
</style>


