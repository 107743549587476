var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"box"},[_c('van-sticky',{attrs:{"z-index":9999,"offset-top":0.1}},[_c('NavBar',[_c('div',{staticClass:"title",staticStyle:{"color":"#fff"},attrs:{"slot":"title"},slot:"title"},[_vm._v(" "+_vm._s(_vm.$t("Mt4ApiMenu.quote"))+" ")])])],1),_c('div',{staticClass:"complex"},[_vm._l((_vm.eightList),function(item,index){return _c('van-cell-group',{key:index,staticClass:"show-item",on:{"click":function($event){return _vm.showGroup(item)}}},[_c('van-swipe-cell',{scopedSlots:_vm._u([{key:"right",fn:function(){return [_c('van-button',{attrs:{"square":"","type":"primary","text":_vm.$t('Mt4ApiMenu.chart'),"color":"#13b5b1"},on:{"click":function($event){return _vm.toChart(item)}}}),_c('van-button',{attrs:{"square":"","type":"danger","text":_vm.$t('Mt4ApiTrade.delete')},on:{"click":function($event){return _vm.removeSymbol(item, index)}}})]},proxy:true}],null,true)},[_c('van-cell',{attrs:{"title":item.symbol,"label":_vm.$t('Mt4ApiTrade.spread') + ': ' + _vm.showSpeed(item)}}),_c('van-cell',{class:[item.bidup ? 'changeRed' : 'changeGreen'],attrs:{"label":_vm.$t('Mt4ApiTrade.low') + ': ' + item.low}},[_c('template',{slot:"title"},[(
                _vm.forPrices(item.bid, item.digits).replace(/\d+\.(\d*)/, '$1')
                  .length >= 3
              )?_c('span',{staticClass:"custom-title"},[_c('span',[_vm._v(_vm._s(_vm._f("numFilter")(_vm.forPrices(item.bid, item.digits))))]),_c('span',{staticClass:"changeBig"},[_vm._v(_vm._s(_vm._f("bigNum")(_vm.forPrices(item.bid, item.digits))))]),_c('span',{staticClass:"power"},[_vm._v(_vm._s(_vm._f("lastNum")(_vm.forPrices(item.bid, item.digits))))])]):(
                _vm.forPrices(item.bid, item.digits).replace(/\d+\.(\d*)/, '$1')
                  .length <= 1
              )?_c('span',{staticClass:"custom-title"},[_c('span',[_vm._v(_vm._s(_vm._f("inBeforeNum")(_vm.forPrices(item.bid, item.digits))))]),_c('span',{staticClass:"changeBig"},[_vm._v(_vm._s(_vm._f("beforeNum")(_vm.forPrices(item.bid, item.digits)))+".")]),_c('span',{staticClass:"power"},[_vm._v(_vm._s(_vm._f("lastBefore")(_vm.forPrices(item.bid, item.digits))))])]):_c('span',{staticClass:"custom-title"},[_c('span',[_vm._v(_vm._s(_vm._f("numFilterTwo")(_vm.forPrices(item.bid, item.digits))))]),_c('span',{staticClass:"changeBig"},[_vm._v(_vm._s(_vm._f("lastTwo")(_vm.forPrices(item.bid, item.digits))))])])])],2),_c('van-cell',{class:[item.askup ? 'changeRed' : 'changeGreen'],attrs:{"label":_vm.$t('Mt4ApiTrade.high') + ': ' + item.high}},[_c('template',{slot:"title"},[(
                _vm.forPrices(item.ask, item.digits).replace(/\d+\.(\d*)/, '$1')
                  .length >= 3
              )?_c('span',{staticClass:"custom-title"},[_c('span',[_vm._v(_vm._s(_vm._f("numFilter")(_vm.forPrices(item.ask, item.digits))))]),_c('span',{staticClass:"changeBig"},[_vm._v(_vm._s(_vm._f("bigNum")(_vm.forPrices(item.ask, item.digits))))]),_c('span',{staticClass:"power"},[_vm._v(_vm._s(_vm._f("lastNum")(_vm.forPrices(item.ask, item.digits))))])]):(
                _vm.forPrices(item.ask, item.digits).replace(/\d+\.(\d*)/, '$1')
                  .length <= 1
              )?_c('span',{staticClass:"custom-title"},[_c('span',[_vm._v(_vm._s(_vm._f("inBeforeNum")(_vm.forPrices(item.ask, item.digits))))]),_c('span',{staticClass:"changeBig"},[_vm._v(_vm._s(_vm._f("beforeNum")(_vm.forPrices(item.ask, item.digits)))+".")]),_c('span',{staticClass:"power"},[_vm._v(_vm._s(_vm._f("lastBefore")(_vm.forPrices(item.ask, item.digits))))])]):_c('span',{staticClass:"custom-title"},[_c('span',[_vm._v(_vm._s(_vm._f("numFilterTwo")(_vm.forPrices(item.ask, item.digits))))]),_c('span',{staticClass:"changeBig"},[_vm._v(_vm._s(_vm._f("lastTwo")(_vm.forPrices(item.ask, item.digits))))])])])],2)],1)],1)}),_c('van-action-sheet',{attrs:{"actions":_vm.actions,"cancel-text":_vm.$t('cancel'),"close-on-click-action":"","description":_vm.symbolName.symbol},on:{"cancel":_vm.onCancel,"select":_vm.onSelect,"closed":_vm.onClosed},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}})],2),_c('div',{staticClass:"addCommon"},[_c('van-row',[_c('van-col',{attrs:{"span":"8"}}),_c('van-col',{attrs:{"span":"8"}},[_c('van-button',{staticStyle:{"border-radius":"6px","width":"100%","height":"0.7rem"},attrs:{"type":"primary","color":"#13b5b1","icon":"plus","size":".1rem"},on:{"click":_vm.addCommond}},[_vm._v(_vm._s(_vm.$t("Mt4ApiTrade.addSymbol")))])],1),_c('van-col',{attrs:{"span":"8"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }