<template>
  <van-nav-bar>
      <slot slot="left" name="left"></slot>
      <slot slot="title" name="title"></slot> 
      <slot slot="right" name="right"></slot>
  </van-nav-bar>
</template>

<script>
export default {

}
</script>

<style>

</style>